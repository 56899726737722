
import { Vue, Component, Ref } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";
import DataEditorDialog from "@/components/helpers/data/editor-dialog.vue";
import AppForm from "@/components/app-form/app-form.vue";
import { Filter } from "@/cms-services/filter";
import moment from "moment";
import { mdiCheck, mdiClose } from "@mdi/js";
import userModule from "@/store/modules/user";
import DataList from "@/components/common/data/list.vue";
@Component({
  components: { AppForm, DataEditorDialog },
})
export default class Home extends Vue {
  @Ref("dataList") dataLsit!: DataList;
  @Ref("editor") editor!: DataEditorDialog;
  moment = moment;
  loaded = false;
  $confirm: any;
  $message: any;
  icons: any = {
    check: mdiCheck,
    close: mdiClose,
  };

  userModule = userModule;

  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 15,
    },
    className: "DataBusRequestMessage",
  });

  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 3000,
    },
  });

  async created() {
    await this.userProfileDataSource.get();
    this.loaded = true;
  }

  createItemModel: any = {
    userProfileId: null,
  };

  createItemFields: any = [
    {
      editor: "enum",
      attrs: {
        label: "Пользователь",
        outlined: true,
      },
      name: "userProfileId",
      config: {
        getItems: "getUserProfiles",
        labelKeyName: "fullName",
        valueKeyName: "id",
        type: "select",
      },
    },
  ];

  customHeaders: any = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "date",
      sortable: true,
    },
    {
      text: "Пользователь",
      value: "fullName",
      sortable: true,
    },
    {
      text: "Состояние",
      value: "state",
      sortable: false,
      align: "left",
    },
  ];

  editorFields: any = [
    {
      editor: "ace",
      name: "data",
      config: {
        lang: "json",
      },
    },
  ];

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Запросы",
        disabled: true,
      },
    ];
  }

  filterModel: any = {
    dateFrom: "",
    dateTo: "",
    userProfileId: "",
  };

  fileterFields: any = [
    {
      editor: "date",
      attrs: {},
      name: "dateFrom",
      grid: { cols: 4 },
      config: {
        attrs: {
          outlined: true,
          label: "От",
          appendIcon: "mdi-calendar",
        },
      },
    },
    {
      editor: "date",
      attrs: {},
      name: "dateTo",
      grid: { cols: 4 },
      config: {
        attrs: {
          outlined: true,
          label: "До",
          appendIcon: "mdi-calendar",
        },
      },
    },
    {
      editor: "auto-complete-select",
      attrs: {
        type: "text",
        outlined: true,
        label: "Выберите пользователя",
        noDataText: "Данные не найдены",
      },
      grid: { cols: 4 },
      name: "userProfileId",
      config: {
        getItems: "getUserProfiles",
        labelKeyName: "fullName",
        valueKeyName: "id",
        type: "select",
      },
    },
  ];

  async filterChanged() {
    this.dataSource.config.filter = Object.entries(this.filterModel)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => new Filter(key, value as string))
      .toFilterString();

    await this.dataSource.get();
  }

  actions: any = {
    getUserProfiles: () => this.userProfileDataSource.items,
  };

  async remove(item) {
    const confirm = await this.$confirm({
      title: "Вы уверены что хотите удалить всё?",
      text: "Восстановление удаленных данных невозможно",
    });
    if (!confirm) return;
    try {
      await this.dataSource.remove(item.id);
      this.$message("Успешно удалено");
      await this.dataLsit.refreshData();
    } catch (error: any) {
      this.$message("Не удалось удалить", "error");
    }
  }

  async viewDataJson(item) {
    const data = JSON.stringify(item.data, null, 2);
    await this.$nextTick();
    await this.editor.update({ data });
  }
}
